import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setToken } from '../store/auth'
import { setCartToken } from '../store/cart'

const Sso = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('token')) {
        dispatch(setToken(searchParams.get('token')))
        if (!searchParams.get('token')) {
          dispatch(setCartToken(''))
        }
        setTimeout(() => {
          const backUrl = searchParams.get('backUrl') || '/'
          window.location.href = backUrl
        }, 2000)
      }
    }
  }, [dispatch])

  return <h1>Loading cart...</h1>
}

export default Sso
